import styled from "styled-components";
import { Background } from "../../../assets/theme/Colors";

const StyledCard = styled.div`
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  border-radius: 0.4rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  overflow: hidden;
  background-color: ${Background.bgLight};
  padding: 0.8rem;
`;

export default StyledCard;
