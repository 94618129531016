import { forwardRef } from "react";
import { MultipleSelect, MultipleSelectProps } from "../MultipleSelect";
import styled from "styled-components";
import { GrayScale } from "../../../../assets/theme/Colors";

const StyledSelectMultipleInput = styled(MultipleSelect)`
    .SelectMultipleInput {
    border-radius: 3rem;
    background-color: ${GrayScale.lightOverDark};

    & .MuiSelect-select {
      padding: 0.5rem 1.5rem;
    }
  }

  .SelectMultipleInput > fieldset {
    border: 0;
  }
`;

export const SelectMultipleInput = forwardRef<HTMLInputElement, MultipleSelectProps>(
  ({ ...props }: MultipleSelectProps, ref) => {
    return (
      <StyledSelectMultipleInput
        selectProps={{ className: "SelectMultipleInput" }}
        {...props}
        ref={ref}
      />
    );
  }
);
SelectMultipleInput.displayName = "SelectMultipleInput";
