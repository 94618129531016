import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import MUIMenuItem from "@mui/material/MenuItem";
import { StyledMuiSelect, Styles } from "./MultipleSelect.styles";

export interface MultipleSelectOptions {
  value: string;
  label: string;
}

export interface MultipleSelectProps extends HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  label?: string | ReactNode;
  error?: string | ReactNode;
  id?: string;
  value?: any[];
  width?: string;
  options: MultipleSelectOptions[];
  onChangeValue?: (newValue: string[]) => void;
  selectProps?: SelectProps;
  name?: string;
  renderValue?: any
}

export const MultipleSelect = forwardRef<unknown, MultipleSelectProps>(
  (
    {
      placeholder,
      label,
      id,
      error,
      value,
      options,
      onChangeValue,
      selectProps,
      width,
      name,
      renderValue,
      ...props
    }: MultipleSelectProps,
    ref
  ) => {
    const changeValueHandler = (event: SelectChangeEvent<unknown>) => {
      const newValue = event.target.value as string[];
      onChangeValue?.(newValue);
    };

    const FormattedLabel = getFormattedLabel(label, id);
    const FormattedError = getFormattedError(error);
    const formattedOptions = placeholder
      ? [{ value: "", label: placeholder }].concat(options)
      : [...options];

    return (
      <Styles {...props} width={width}>
        {FormattedLabel}
        <StyledMuiSelect
          {...selectProps}
          displayEmpty
          renderValue={renderValue}
          value={value}
          MenuProps={{ className: "Select" }}
          onChange={changeValueHandler}
          labelId={id}
          ref={ref}
          name={name}
          multiple
        >
          {formattedOptions.map((item) => {
            return (
              <MUIMenuItem key={item.value} value={item.value}>
                {item.label}
              </MUIMenuItem>
            );
          })}
        </StyledMuiSelect>
        {FormattedError}
      </Styles>
    );
  }
);

MultipleSelect.displayName = "MultipleSelect";
MultipleSelect.defaultProps = {
  value: [],
  options: [],
};

function getFormattedLabel(
  label: MultipleSelectProps["label"],
  id: MultipleSelectProps["id"]
): ReactNode {
  if (!label) {
    return null;
  }

  if (typeof label === "string") {
    return (
      <label htmlFor={id} className="Label">
        {label}
      </label>
    );
  }

  return label;
}

function getFormattedError(error: MultipleSelectProps["error"]): ReactNode {
  if (!error) {
    return null;
  }

  if (typeof error === "string") {
    return <span className="Error">{error}</span>;
  }

  return error;
}
