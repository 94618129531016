import {lighten} from 'polished';
import styled from 'styled-components';
import {GrayScale, MainColors, Status} from '../../../../assets/theme/Colors';
import {Button} from '../Button';

export const ButtonTextPrimary = styled(Button)`
  color: ${({disabled, loading}) => (disabled && !loading ? Status.disabled : MainColors.primary)};
  border-radius: 0.25rem;
  padding: 0.5rem 1.5rem;
  transition: all 400ms ease-in-out;

  &:hover {
    color: ${MainColors.primaryDark};
  }
`;
