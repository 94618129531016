import {HTMLAttributes, ReactNode} from 'react';
import {SpinnerPresets} from '../Spinners';
import {Styles} from './Button.styles';

export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  loading?: boolean;
  loader?: ReactNode;
  disabled?: boolean;
}

export const Button = ({children, label, loading, loader, ...props}: ButtonProps) => {
  const InnerContent = (function () {
    if (loading) {
      return loader ? loader : <SpinnerPresets.Circular className='Button__loader' />;
    }

    if (label) {
      return label;
    }

    return children;
  })();

  return <Styles {...props}>{InnerContent}</Styles>;
}
