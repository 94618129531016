import {HTMLAttributes} from 'react';
import styled from 'styled-components';
import {GrayScale} from '../../../../assets/theme/Colors';

// Source: https://loading.io/css/
const Styles = styled.div<CircularProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${({size}) => `calc(${size}px * 1.25)`};
  width: ${({size}) => `calc(${size}px * 1.25)`};

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
    margin: 8px;
    border: ${({thickness}) => `${thickness}px`} solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({color}) => color} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface CircularProps extends HTMLAttributes<HTMLDivElement> {
  color?: string;
  size?: number;
  thickness?: number;
}

export const Circular = ({color, size, thickness, ...props}: CircularProps) => {
  return (
    <Styles color={color} size={size} thickness={thickness} {...props}>
      <div />
      <div />
      <div />
      <div />
    </Styles>
  );
}

Circular.defaultProps = {
  color: GrayScale.lightOverDark,
  size: 16,
  thickness: 2,
};
