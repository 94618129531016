import {lighten} from 'polished';
import styled from 'styled-components';
import {GrayScale, MainColors, Status} from '../../../../assets/theme/Colors';
import {SpinnerPresets} from '../../Spinners';
import {Button, ButtonProps} from '../Button';

const Styles = styled(Button)`
  background-color: ${({disabled, loading}) => (disabled && !loading ? Status.disabled : MainColors.primary)};
  color: ${GrayScale.lightOverDark};
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  transition: all 400ms ease-in-out;

  &:hover {
    background-color: ${lighten(0.15, MainColors.primary)};
  }
`;

export function ButtonFilledPrimary({...props}: ButtonProps) {
  return <Styles {...props} loader={<SpinnerPresets.Circular className='Button__loader' />} />;
}
