import styled from "styled-components";
import { MainColors } from "../../../../assets/theme/Colors";

export const TitleH1Primary = styled.h1`
  text-align: center;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.5;
  color: ${MainColors.primary};
`;
