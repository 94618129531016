import {HTMLAttributes, PropsWithChildren} from 'react';
import {TextPresets} from '../Text';
import StyledCard from './Card.styles';

export interface CardProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  disabled?: boolean;
  image?: string;
}

export const Card = ({children, ...props}: CardProps) => {
  if (typeof children === 'string') {
    return (
      <StyledCard {...props}>
        <TextPresets.Body>{children}</TextPresets.Body>
      </StyledCard>
    );
  }

  return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
